import React from 'react'
import { CardSettings } from './CardSettings'

const Settings = () => {
  return (
    <>
      <CardSettings />
    </>
  )
}

export default Settings